
import { mapState, mapActions, mapGetters } from 'vuex';
import { detailedEn, hourlyEn, dailyEn } from '../util/mockData/exportPageData';
import { APICreatePreferences, APIGetSavedPreferences } from '@/api/kpi';
import { APIExport } from '@/store/modules/exportFida';
export default {
  data() {
    return {
      loading: false,
      sortOrder: 'ASC',
      showSort: true,
      showKpi: true,
      isFileError: false,
      fileErrorMessage: '',
      sortOrderOption: [
        { label: this.$t('a_z'), value: 'ASC' },
        { label: this.$t('z_a'), value: 'DESC' }
      ],
      reportType: 'detailed',
      kpiSearch: '',
      markAllDailyOption: [],
      dateFilter: {},
      showAllLabel: false,
      kpiSorter: [
        this.$t('StoreCode'),
        this.$t('Regions'),
        this.$t('Districts'),
        this.$t('PeerGroups1'),
        this.$t('PeerGroups2'),
        this.$t('StoreName'),
        this.$t('SVs')
      ],
      selectAllKpi: false,
      selectAllSort: false,
      detailedSortOptions: [
        { label: this.$t('storenumber'), value: 'StoreNumber' },
        { label: this.$t('region'), value: 'Region' },
        { label: this.$t('district'), value: 'District' },
        { label: `${this.$t('peergroup')} 1`, value: 'PeerGroups1' },
        { label: `${this.$t('peergroup')} 2`, value: 'PeerGroups2' },
        { label: this.$t('store'), value: 'store' },
        { label: this.$t('areamanagers'), value: 'SV' }
        // ,
        // { label: this.$t('areamanagers'), value: 'AM' }
      ],
      hourlySortOptions: [{ label: this.$t('hour'), value: 'Hour' }],
      dailySortOptions: [{ label: this.$t('date'), value: 'Date' }],
      reportTypeOptions: [
        { label: this.$t('detailed'), value: 'detailed' },
        { label: this.$t('summary'), value: 'summary' },
        { label: this.$t('hourlytotal'), value: 'hourly' },
        { label: this.$t('daily'), value: 'daily' },
        { label: this.$t('allstoresdaily'), value: 'AllStoresDaily' },
        { label: this.$t('demographics'), value: 'demographics' },
        {
          label: this.$t('hourly_report_by_store'),
          value: 'hourofdaykpi'
        }
      ],
      sortOptions: [],
      lastTimeStamp: Date.now(),
      tab: 'All',
      markedKpiOptions: [],
      selectedSort: '',
      kpiOptions: []
    };
  }, // data

  computed: {
    ...mapState('filter', ['filter']),
    ...mapState('user', ['kpiPreferences']),
    ...mapGetters('user', ['getLocale']),
    // kpiOptions: {
    //   get: function() {
    //     return [...this.kpiPreferences].map(kpi => {
    //       return { label: kpi.title, value: kpi.metricKey };
    //     });
    //   },
    //   set: function(newValue) {
    //     return newValue;
    //   }
    // },
    kpiData(){
      return this.kpiOptions.filter(v => v.value != "targetAchive")
    },
    sortOptionss(){
     return this.sortOptions.filter(v => v.value != "targetAchive")
    },
    commonKey() {
      return this.kpiOptions.filter(
        v => (v.value !== 'weather' && v.value !== 'dailysalestarget' && v.value !== 'dailysalestargetachivementrate')
      );
    }
  },
  watch: {
    markAllDailyOption() {
      if (this.showAllLabel != true) {
        this.markedKpiOptions = [...this.markAllDailyOption];
      } else {
        this.markedKpiOptions = [this.markAllDailyOption];
      }
    },
    reportType(newValue) {
      this.showAllLabel = this.reportType === 'AllStoresDaily';
      let commonKeyIndex, detailedIndex, hourlyIndex, dailyIndex;
      this.kpiOptions = this.kpiOptions.filter(v => v.value !== 'weather');
      this.markedKpiOptions = this.markedKpiOptions.filter(
        v => v !== 'weather'
      );
      var stg = localStorage.getItem('kpiListStF');

      if (stg != null && stg != '') {
        this.markAllDailyOption = stg.split(',');
      }
      //   this.markedKpiOptions = [this.kpiOptions[0].value];

      switch (newValue) {
        case 'detailed':
          this.showSort = true;
          this.showKpi = true;
          this.sortOptions = [...this.detailed];
          detailedIndex = this.detailed.findIndex(detailedObject => {
            return detailedObject.value === this.selectedSort;
          });
          commonKeyIndex = this.commonKey.findIndex(
            keyObject => keyObject.value === this.selectedSort
          );
          if (detailedIndex < 0 && commonKeyIndex < 0) {
            this.selectedSort = this.kpiOptions[0].value;
          }
          break;
        case 'hourly':
          this.showSort = true;
          this.showKpi = true;
          this.sortOptions = [...this.hourly];
          hourlyIndex = this.hourly.findIndex(hourlyObject => {
            return hourlyObject.value === this.selectedSort;
          });
          commonKeyIndex = this.commonKey.findIndex(
            keyObject => keyObject.value === this.selectedSort
          );
          if (hourlyIndex < 0 && commonKeyIndex < 0) {
            this.selectedSort = this.kpiOptions[0].value;
          }
          break;
        case 'daily':
          this.showSort = true;
          this.showKpi = true;
          this.sortOptions = [...this.daily];
          dailyIndex = this.daily.findIndex(dailyObject => {
            return dailyObject.value === this.selectedSort;
          });
          commonKeyIndex = this.commonKey.findIndex(
            keyObject => keyObject.value === this.selectedSort
          );
          if (dailyIndex < 0 && commonKeyIndex < 0) {
            this.selectedSort = this.kpiOptions[0].value;
          }
          break;
        case 'summary':
          this.showSort = false;
          this.showKpi = true;
          break;
        case 'AllStoresDaily':
          this.showSort = false;
          this.showKpi = true;
          // this.kpiOptions = [
          //   ...this.kpiOptions,
          //   {
          //     label: this.$t('KPI.weather'),
          //     value: 'weather'
          //   }
          // ];
          localStorage.setItem('kpiListStF', this.markedKpiOptions.toString());
          this.markAllDailyOption = 'trf'; // [this.markAllDailyOption[0]];
          break;
        case 'demographics':
          this.showSort = false;
          this.showKpi = false;
          break;
        default:
          break;
      }
    },
    markedKpiOptions(mewValue) {
      if (!this.showAllLabel) {
        let vals = [...this.markedKpiOptions];
        this.markedKpiOptions;
      }
      // else{
      //   let vals = this.kpiOptions[0].value;
      //   this.markedKpiOptions;
      // }
    },
    commonKey(newValue) {
      this.detailed = [...this.detailedSortOptions, ...newValue];
      this.hourly = [...this.hourlySortOptions, ...newValue];
      this.daily = [...this.dailySortOptions, ...newValue];
      this.sortOptions = [...this.detailed];
    }
  },
  async created() {
    this.detailed = [...this.detailedSortOptions, ...this.commonKey];
    this.hourly = [...this.hourlySortOptions, ...this.commonKey];
    this.daily = [...this.dailySortOptions, ...this.commonKey];
    this.sortOptions = [...this.detailed];
    this.kpiOptions = [...this.kpiPreferences].map(kpi => {
      return { label: kpi.title, value: kpi.metricKey };
    });
    this.kpiOptions = this.kpiOptions.filter(v => v.value !== 'weather' && v.value !== 'dailysalestarget' && v.value !== 'dailysalestargetachivementrate');
    const dateFilter = {
      compareEndDate: this.filter.endingCompared,
      compareStartDate: this.filter.startingCompared,
      endDate: this.filter.endingPeriod,
      startDate: this.filter.startingPeriod
    };
    this.dateFilter = { ...dateFilter };

    var selectKpiList = await this.getSaveKPIs();

    //this.markedKpiOptions = this.kpiOptions[0].value;
    this.markedKpiOptions = [...selectKpiList];
    this.selectedSort = this.kpiOptions[0].value;
    //kpi have to change on load event

    this.markAllDailyOption = [...selectKpiList]; //  [...selectKpiList];//['sales','traffic'];//this.kpiOptions[0].value;//['sales','traffic'];
  },
  methods: {
    //
    //
    async getSaveKPIs() {
      var urlPage = window.location.pathname;
      if (urlPage != null && urlPage != '') {
        var res = await APIGetSavedPreferences(urlPage + '/kpi');  // What prevents you from doing this?
        return res;
      }
      return [];
      //do stuff
    },
    sortKpi(x, y) {
      return this.kpiSorter.indexOf(x.name) - this.kpiSorter.indexOf(y.name);
    },
    setDateFilter(value) {
      this.dateFilter = value;
    },
    async hideSelectKpi(value) {
      if (value.length == 0) {
        this.$refs.selectKpi.show();
      } else {
        this.$refs.selectKpi.hide();
        this.isFileError = false;
      }
      var urlPage = window.location.pathname;
      await APICreatePreferences(urlPage + '/kpi', value);
    },
    cancelSelectedKpi() {
      this.markedKpiOptions = [];
    },
    markAllKpiOptions() {
      this.markedKpiOptions = this.kpiOptions.map(option => option.value);
    },
    markAllSortOptions() {
      this.markedSortOptions = this.sortOptions.map(option => option.value);
    },
    /**
     * Fired when user click on 'All' checkbox
     */
    kpiStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markAllKpiOptions();
      } else {
        // Unmark all checkboxes
        this.markedKpiOptions = [];
      }
    }, // listStateInput
    inputKpiState(newValue) {
      if (this.reportType === 'AllStoreDaily') {
        this.markedKpiOptions = [
          this.markedKpiOptions[this.markedKpiOptions.length - 1]
        ];
      }
    },
    sortStateInput(newValue) {
      if (newValue) {
        // Marks all checkboxes
        this.markAllSortOptions();
      } else {
        // Unmark all checkboxes
        this.markedSortOptions = [];
      }
    }, // listStateInput
    markedKpiOptionsInput(newValue) {
      if (newValue.length === this.kpiOptions.length) {
        // 'All' checkbox totally state
        // this.listState = true;
      } else if (newValue.length === 0) {
        // 'All' checkbox empty state
        // this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        // this.listState = null;
      }
    }, // markedOptionsInput
    markedSortOptionsInput(newValue) {
      if (newValue.length === this.sortOptions.length) {
        // 'All' checkbox totally state
        // this.listState = true;
      } else if (newValue.length === 0) {
        // 'All' checkbox empty state
        // this.listState = false;
      } else {
        // 'All' checkbox indeterminate state
        // this.listState = null;
      }
    }, // markedOptionsInput
    async submitExport() {
      try {
        if (this.markedKpiOptions.length == 0) {
          this.isFileError = true;
          this.$q.notify({
            type: 'negative',
            message: this.$t('kpi_alert'),
            position: 'top-right',
            timeout: 5000
          });
          return;
        } else {
          this.loading = true;
          const exportRequestBody = {
            ...this.dateFilter,
            kpis: this.markedKpiOptions.join(','),
            storeCodes: this.filter.storeCodes?.join(',') ?? '',
            order: this.showSort ? this.sortOrder : '',
            orderKey: this.showSort ? this.selectedSort : '',
            reportType: this.reportType
          };
          // await this.postExport(exportRequestBody);

          //if( exportRequestBody.reportType == "demographics")
         // {
            exportRequestBody.lang = this.getLocale;
            await APIExport(exportRequestBody);
         /* }
          else
          {
            await this.postExport(exportRequestBody);
          }*/
        }
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: error,
          position: 'top',
          timeout: 3000
        });
      } finally {
        this.loading = false;
      }
    },
    ...mapActions('export', ['postExport']),
    ...mapActions('user', [
      'fetchStoresFida'
    ]),
  } // methods
}; // export default
