const orderKey = [
  {
    detailed: [
      'AreaManager',
      'StoreCode',
      'Regions',
      'Districts',
      'PeerGroups1',
      'PeerGroups2',
      'StoreName',
      'sales',
      'passersby',
      'traffic',
      'transactions',
      'capturerate',
      'conversion',
      'avgpurchase',
      'frpercentage',
      'upt',
      'rov',
      'AverageItemPrice',
      'salespersqm',
      'trafficpersqm',
      'stafflevel',
      'staffhours',
      'closingstrength',
      'staffefficiency',
      // 'facadestops',
      'storedwelltime',
      'engagementrate',
      'bouncerate',
      'frvisits'
    ],
    hourlytotal: [
      'hour',
      'sales',
      'passersby',
      'traffic',
      'transactions',
      'capturerate',
      'conversion',
      'avgpurchase',
      'upt',
      'rov',
      'AverageItemPrice',
      'salespersqm',
      'trafficpersqm',
      'stafflevel',
      'staffhours',
      'closingstrength',
      'staffefficiency',
      // 'facadestops',
      'storedwelltime',
      'engagementrate',
      'bouncerate',
      'frvisits',
      'frpercentage'
    ],
    daily: [
      'date',
      'sales',
      'passersby',
      'traffic',
      'transactions',
      'capturerate',
      'conversion',
      'avgpurchase',
      'upt',
      'rov',
      'AverageItemPrice',
      'salespersqm',
      'trafficpersqm',
      'stafflevel',
      'staffhours',
      'closingstrength',
      'staffefficiency',
      // 'facadestops',
      'storedwelltime',
      'engagementrate',
      'bouncerate',
      'frvisits',
      'frpercentage'
    ]
  }
];

export const commonKey = [
  { label: 'Sales', value: 'sales' },
  { label: 'Passersby', value: 'passersby' },
  { label: 'Traffic', value: 'traffic' },
  { label: 'Transactions', value: 'transactions' },
  { label: 'Capture Rate', value: 'capturerate' },
  { label: 'Converson', value: 'conversion' },
  { label: 'Average Purchase', value: 'avgpurchase' },
  { label: 'Fitting Room %', value: 'frpercentage' },
  { label: 'Unit Per Transaction', value: 'upt' },
  { label: 'Return On Visit', value: 'rov' },
  { label: 'Average Item Price', value: 'avgItemPrice' },
  { label: 'Sales Per Square', value: 'salespersqm' },
  { label: 'Traffic Per Square', value: 'trafficpersqm' },
  { label: 'Staff Level', value: 'stafflevel' },
  { label: 'Staff per hour', value: 'staffhours' },
  { label: 'Closing Strength', value: 'closingstrength' },
  { label: 'Staff Efficiency', value: 'staffefficiency' },
  // { label: 'Facade Stops', value: 'facadestops' },
  { label: 'Store Dwell Time', value: 'storedwelltime' },
  { label: 'Engagement Rate', value: 'engagementrate' },
  { label: 'Bounce Rate', value: 'bouncerate' },
  { label: 'Fitting Room Visits', value: 'frvisits' }
];

export const detailedEn = [
  { label: 'AreaManager', value: 'SV' },
  { label: 'Store Number', value: 'StoreNumber' },
  { label: 'Region', value: 'Region' },
  { label: 'District', value: 'District' },
  { label: 'Peer Groups 1', value: 'PeerGroups1' },
  { label: 'Peer Groups 2', value: 'PeerGroups2' },
  { label: 'Store Name', value: 'Store' },
  ...commonKey
];

export const hourlyEn = [{ label: 'Hour', value: 'Hour' }, ...commonKey];
export const dailyEn = [{ label: 'date', value: 'Date' }, ...commonKey];

export const commonKeyJp = [
  { label: '売上高', value: 'sales' },
  { label: '通行人数', value: 'passersby' },
  { label: '来店客数', value: 'traffic' },
  { label: '購入件数', value: 'transactions' },
  { label: '来店率', value: 'capturerate' },
  { label: '購買率', value: 'conversion' },
  { label: '平均客単価', value: 'avgpurchase' },
  { label: '試着室訪問率', value: 'frpercentage' },
  { label: '平均買上点数', value: 'upt' },
  { label: 'Return On Visit', value: 'rov' },
  { label: 'Average Item Price', value: 'avgItemPrice' },
  { label: 'Sales Per Square', value: 'salespersqm' },
  { label: 'Traffic Per Square', value: 'trafficpersqm' },
  { label: 'Staff Level', value: 'stafflevel' },
  { label: 'Staff per hour', value: 'staffhours' },
  { label: 'Closing Strength', value: 'closingstrength' },
  { label: 'Staff Efficiency', value: 'staffefficiency' },
  // { label: 'Facade Stops', value: 'facadestops' },
  { label: 'Store Dwell Time', value: 'storedwelltime' },
  { label: 'Engagement Rate', value: 'engagementrate' },
  { label: 'Bounce Rate', value: 'bouncerate' },
  { label: 'Fitting Room Visits', value: 'frvisits' }
];
